/* eslint-disable */
'use strict';

function browserId() {
  var tem;
  var ua = navigator.userAgent;
  var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
}

if (browserId() === 'IE 11') {
  var baseUrl = window.location.origin;
  window.location.replace(baseUrl + '/resources/unsupportedBrowser.html');
}
